<template>
  <div>
    <!-- Banner -->
    <banner-version-one
      :title="title"
      :desc="desc"
      :bg-image-url="data.bannerImage"
    />

    <!-- Popular Categories -->
    <popular-categories
      :title="data.popularCategoriesTitle"
      :desc="data.popularCategoriesDesc"
      :data="data.categories"
    />

    <div class="">
      <div class="block-space bg-secondary">
        <div class="container">
          <div class="row row-grid align-items-center">
            <div class="col-md-6">
              <div class="card bg-default shadow border-0">
                <img
                  src="/static/images/about_main.jpg"
                  class="card-img-top"
                >
                <blockquote class="card-blockquote">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="svg-bg"
                  >
                    <polygon
                      points="0,52 583,95 0,95"
                      class="fill-default"
                    />
                    <polygon
                      points="0,42 583,95 683,0 0,95"
                      opacity=".2"
                      class="fill-default"
                    />
                  </svg>
                  <h4 class="display-3 font-weight-bold text-white">
                    Our Goal
                  </h4>
                  <p class="lead text-italic text-white">
                   To create a dedicated platform where users can search and review NEARBY entertainment such as happy hours, sports bars, live music, karaoke, and gaming-arcade.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="col-md-6">
              <div class="pl-md-5">
                <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                  <i class="fa fa-info" />
                </div>
                <br>
                <h3 style="display:inline;">
                  About Us
                </h3><span><router-link
                  class="font-weight-bold"
                  to="/about-us"
                >More</router-link></span>
                <p class="lead text-justify">
                  Need a night out? Thriggle is the easy way to find fun and affordable entertainment. 
                  Our platform links users with bars and restaurants in their area.
                  Unlike large “one size fits all” platforms, our service helps people find the specific types of events they’re interested in.
                  Whether you want to discover local music, cheer on your favorite sports team, or enjoy an evening of family fun our directory connects you with exciting events happening nearby.
                </p>
                <router-link
                  class="btn btn-success"
                  to="sign-up"
                >
                  Join Now
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Most Visited Places -->
    <!-- <most-visited-places :title="data.mostVisitedPlacesTitle" :desc="data.mostVisitedPlacesDesc" :data="data.places"></most-visited-places> -->

    <!-- Services Section -->
    <!-- <services-section :title="data.servicesTitle" :desc="data.servicesDesc" :data="data.services"></services-section> -->

    <!-- Team Section -->
    <team
      :title="data.teamSectionTitle"
      :desc="data.teamSectionDesc"
      :data="data.teamMembers"
    />

    <!-- Recent Blog Posts -->
    <!-- <recent-blog :title="data.recentBlogTitle" :data="data.blogs"></recent-blog> -->
  </div>
</template>

<script>
import Banner from 'Components/globalFrontendComponents/Banner';
import PopularCategories from 'Components/globalFrontendComponents/PopularCategories';
// import MostVisitedPlaces from 'Components/globalFrontendComponents/MostVisitedPlaces';
// import Services from 'Components/globalFrontendComponents/Services';
import Team from 'Components/globalFrontendComponents/Team';
// import RecentBlog from 'Components/globalFrontendComponents/RecentBlog';

import MyData from 'Data/dashboardone.json';
export default {
	components :{
		bannerVersionOne: Banner,
		PopularCategories : PopularCategories,
		// MostVisitedPlaces: MostVisitedPlaces,
		// ServicesSection: Services,
		Team: Team,
		// RecentBlog: RecentBlog
	},
	data(){
		return{
			data: MyData.data,
			title: 'START PLANNING YOUR NEXT NIGHT OUT',
			desc: 'Find top rated bars, restaurants, and events near you.',
		}
	},
  mounted(){
     var check = $('#navbar_global').hasClass('show');
    if(check)
      $('#navbar_global').removeClass('show');
  }
};
</script>
